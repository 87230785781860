import request from '../request.js'

export function adminSelectColor(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/admin/getColor',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
export function upColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/up/color',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}
export function setColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/set/color',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}
export function delColor(fFiveElementColors){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/del/color',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementColors//向后端传递数据
    })
}
// 获取五行婚姻
export function adminSelectMarriage(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/get/hunYin',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
// 增加五行婚姻
export function addMarriage(fFiveElementMarriage){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/add/hunYin',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementMarriage//向后端传递数据
    })
}
// 删除五行婚姻
export function delMarriage(fFiveElementMarriage){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/del/hunYin',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementMarriage//向后端传递数据
    })
}
// 修改五行婚姻
export function upMarriage(fFiveElementMarriage){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/up/hunYin/by/id',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementMarriage//向后端传递数据
    })
}
// 获取五行财运
export function adminSelectWealth(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/get/caiYun',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
// 增加五行财运
export function addWealth(fFiveElementWealth){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/add/caiYun',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementWealth//向后端传递数据
    })
}
// 删除五行财运
export function delWealth(fFiveElementWealth){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/del/caiYun',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementWealth//向后端传递数据
    })
}
// 修改五行财运
export function upWealth(fFiveElementWealth){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/up/caiYun/by/id',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementWealth//向后端传递数据
    })
}
// 获取五行疾病
export function adminSelectDisease(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/get/jiBing',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
// 删除五行疾病
export function delDisease(fFiveElementDisease){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/del/jiBing',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementDisease//向后端传递数据
    })
}
// 增加五行疾病
export function addDisease(fFiveElementDisease){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/add/jiBing',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementDisease//向后端传递数据
    })
}
// 修改五行疾病
export function upDisease(fFiveElementDisease){
    return request({  //利用我们封装好的request发送请求
        url: '/explainOrally/up/jiBing/by/id',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementDisease//向后端传递数据
    })
}
// 获取五行命理测算内容标题列表
export function adminSelectTitle(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/title/admin/get/list',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
// 修改五行命理测算内容标题列表
export function upTitle(fFiveElementTitle){
    return request({  //利用我们封装好的request发送请求
        url: '/title/admin/update/title',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementTitle//向后端传递数据
    })
}
// 获取五行补救
export function adminSelectRemediation(pageParameter){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/admin/get/remedial/five/elements',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:pageParameter//向后端传递数据
    })
}
// 修改五行补救
export function upRemediation(fFiveElementRemediation){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/up/remedial/five/elements',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementRemediation//向后端传递数据
    })
}
// 新增五行补救
export function addRemediation(fFiveElementRemediation){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/admin/add/remedial/five/elements',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementRemediation//向后端传递数据
    })
}
// 删除五行补救
export function delRemediation(fFiveElementRemediation){
    return request({  //利用我们封装好的request发送请求
        url: '/theFiveElements/admin/del/remedial/five/elements',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:fFiveElementRemediation//向后端传递数据
    })
}

